.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
}

a {
  text-decoration: none;
  color: #006eff;
}

input {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}


button {
  flex:1;
  background-color: #006eff; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

h1 {
  font-size: 24px;
  margin: 0;
}

.shadow {
  text-shadow: 2px 2px 4px #000000;
}

h2 {
  font-size: 20px;
  margin: 0;
  margin-top: 5px;
}

h3 {
  font-size: 16px;
  margin: 0;
}


p {
  font-size: 12px;
}

.button2 {
  background-color: #0fa341; /* Green */
}

button:hover {
  background-color: #1e4d80;
}

.logo {
  display: flex;
  width: 100px;  
  margin: 0 auto;
  flex:1;
  align-self: flex-start;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  padding: 4px;
  color:#006eff;
  cursor: pointer;
}

.logoimage {
  width: 100px;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: white;
}

.spaceout {
  flex: 2;
}

.topsearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  margin-top: 20px;
  padding: 10px;

}

.toplogo {
  display: flex;
  flex-direction: row;
  
  align-items: center;
  width: 90%;
  flex:1;
}

.searchresult {
  display: flex;
}

.searchresult img {
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .searchresult img {
    width: 100px;
  }

  .menu {
    flex-direction: column;
  }

  button {
    width: 80%;
  }
  
}

.pane {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);  
  border-radius: 10px;  
  margin: auto;
  width: 50%;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 5px;
}

.form-group label {
  font-size: 16px;
  color: white;
  min-width: 150px;
}

.waitinglist {
  background-image: url("wonderland.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin: 0;  
  padding-top: 10px;
}

.waiting-type-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;  

}

@media screen and (max-width: 600px) {
  .waiting-type-list {
    
  }

  .pane {
    margin: auto;
    width: 90%;
    padding-bottom: 10px;;
  }

  .innerpane {
    padding: 10px;
  }

  .waitinglist {  
  }

  .form-group label {
    min-width: 100px;
  }
  
}